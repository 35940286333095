import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import Container from "./pages/Container";

// Pages
import Home from "./pages/Home";
import MVA from "./pages/MVA";
import FAQ from "./pages/FAQ";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Container page="home">
              <Home type={"drift"} />
            </Container>
          }
        />
        <Route
          path="/konto-inv"
          element={
            <Container page="konto-inv">
              <Home type={"inv"} />
            </Container>
          }
        />
        <Route
          path="/mva"
          element={
            <Container page="mva">
              <MVA />
            </Container>
          }
        />
        <Route
          path="/faq"
          element={
            <Container page="faq">
              <FAQ />
            </Container>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
