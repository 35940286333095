import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

//export default function Search({ updateData, updateCard, type }) {
const Search = forwardRef((props, ref) => {
  const { updateData, updateCard, updateKeyword, type } = props;

  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState([]);
  const [activeCard, setActiveCard] = useState(0);

  const searchDiv = useRef(null);
  const scrollToDiv = useRef(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  const [searchOption, setSearchOption] = useState("beskrivelse");
  const optionRef = useRef(null);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const [urlKonto, setURLKonto] = useState("");

  useEffect(() => {
    setURLKonto(urlSearchParams.get("konto"));
  }, []);

  useEffect(() => {
    if (scrollToDiv.current) {
      if (!hasScrolled) {
        scrollToDiv.current.scrollIntoView({ behavior: "smooth" });
        setHasScrolled(true);

        // Finn indeksen til elementet i 'data' som har samme verdi som 'prev.konto'
        const index = results.findIndex((item) => item.konto === urlKonto);
        // Returner det første elementet i 'data' hvis ingen match ble funnet, ellers returner elementet ved indeksen
        index !== -1 ? setActiveCard(index) : setActiveCard(0);

        setURLKonto(null);

        // Fjern 'konto'-parameteren fra URL-en
        urlSearchParams.delete("konto");

        // Fjern også '?' fra starten av URL-en hvis det er der
        const newUrl =
          window.location.pathname +
          (urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : "");

        window.history.replaceState(null, "", newUrl);
      }
    }
  }, [results, hasScrolled]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Søkebetingelser
    // Definer en forsinkelsestid (for eksempel 300 ms)
    const delay = 200;

    // Slett tidligere timeout (hvis det finnes)
    let timeoutId;

    // Sett ny timeout
    timeoutId = setTimeout(() => {
      getData();
      updateKeyword(keyword);
    }, delay);

    // Rydd opp i timeout når komponenten avmonteres eller keyword endres
    return () => clearTimeout(timeoutId);
  }, [keyword, type]);

  const getData = async (updatedKonto) => {
    // Keyword - Søkeord
    if (keyword.length >= 3) {
      fetch(
        `https://konto.tkva.no/api/konto.php?type=${type}&keyword=${keyword}&option=${searchOption}`
      )
        .then((response) => response.json())
        .then((data) => {
          setResults(data);
          updateData(data);

          if (updatedKonto) {
            const index = data.findIndex((item) => item.konto === updatedKonto);
            index !== -1 ? setActiveCard(index) : setActiveCard(0);
          } else {
            setActiveCard(0);
          }
        });
    } else {
      // Uten søkeord
      fetch(`https://konto.tkva.no/api/konto?type=${type}`)
        .then((response) => response.json())
        .then((data) => {
          setResults(data);
          updateData(data);

          if (updatedKonto) {
            const index = data.findIndex((item) => item.konto === updatedKonto);
            index !== -1 ? setActiveCard([index]) : setActiveCard(0);
          } else {
            setActiveCard(0);
          }
        });
    }
  };

  useImperativeHandle(ref, () => ({
    getData,
  }));

  useEffect(() => {
    updateCard(activeCard);
  }, [activeCard]);

  function stripTags(input) {
    // Sjekk om input er null eller undefined
    if (input === null || input === undefined) {
      return ""; // Returner en tom streng eller håndter på annen måte etter behov
    }

    // Fjern HTML-tags
    var withoutTags = input.replace(/<\/?[^>]+(>|$)/g, "");

    // Dekode HTML-entiteter
    var decodedString = withoutTags.replace(
      /&([^\s;]+);/g,
      function (match, entity) {
        var decodeEntities = {
          amp: "&",
          lt: "<",
          gt: ">",
          quot: '"',
          apos: "'",
          nbsp: " ",
          ouml: "ö", // Legg til flere entiteter etter behov
          oslash: "ø",
          aring: "å",
          aelig: "æ",
          // Legg til flere entiteter etter behov
        };
        return decodeEntities[entity] || match;
      }
    );
    return decodedString;
  }

  return (
    <div
      className="col-sm-4"
      style={{
        backgroundImage: `url(https://images.unsplash.com/photo-1519750783826-e2420f4d687f?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGJyaWdodHxlbnwwfHwwfHx8MA%3D%3D)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: 10,
        borderRadius: 0,
        minHeight: "90vh",
      }}
    >
      <div
        className="col"
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          paddingLeft: 5,
          minHeight: "100%",
          borderRadius: 3,
          padding: 5,
        }}
      >
        <div className="input-group mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Søk..."
            aria-label="Søk.."
            aria-describedby="basic-addon2"
            onChange={(e) => setKeyword(e.target.value)}
            style={{
              boxShadow: "none",
            }}
          />
          <div className="input-group-append">
            <select
              ref={optionRef}
              className="form-select"
              onChange={(e) => setSearchOption(e.target.value)}
              defaultValue={"beskrivelse"}
              style={{
                background: "#f1f1f1",
                color: "black",
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                marginRight: 5,
                boxShadow: "none",
              }}
            >
              <option defaultValue={"beskrivelse"} value={"beskrivelse"}>
                Beskrivelse
              </option>
              <option value={"konto"}>Konto</option>
            </select>
          </div>
          <div className="input-group-append">
            <button
              className="btn"
              type="button"
              onClick={() => optionRef.current.focus()}
            >
              <i className="bi bi-chevron-expand" />
            </button>
          </div>
        </div>
        <hr />

        <div
          ref={searchDiv}
          style={{ height: "78vh" }}
          className="overflow-auto"
        >
          {results.map((res, key) => {
            return (
              <div
                className={
                  activeCard === key ? "card bg-secondary" : "card bg-dark"
                }
                key={key}
                style={{ width: "90%", margin: 15, cursor: "pointer" }}
                onClick={() => setActiveCard(key)}
                ref={
                  res.konto.toString() === urlKonto?.toString()
                    ? scrollToDiv
                    : null
                }
              >
                <div className="card-header">
                  <p style={{ fontSize: 20, padding: 0, margin: 0 }}>
                    {res.konto} - {res.kontonavn}
                  </p>
                </div>
                <div
                  className="card-body"
                  style={{ backgroundColor: "lightgrey", margin: 2 }}
                >
                  <blockquote className="blockquote mb-0">
                    <p
                      style={{ color: "black", fontWeight: 400, fontSize: 18 }}
                      className="text-truncate"
                    >
                      {stripTags(res.beskrivelse)}
                    </p>
                  </blockquote>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default Search;
