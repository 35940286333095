import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";

export default function Container({ children, page }) {
  const [curPage, setPage] = useState("home");

  useEffect(() => {
    setPage(page);
  }, [page]);

  return (
    <div className="App">
      <div className="container-fluid d-none">
        <div className="row">
          <div className="col-sm">
            <img
              className="img-fluid"
              alt="Logo"
              style={{ height: 125 }}
              src="https://tromso.kommune.no/sites/default/files/styles/large/public/2022-02/TromsoKommune_HOVEDLOGO_Hovedlogo%20RGB.png?itok=EfsXx5kA"
            />
          </div>
          <div className="col-sm">&nbsp;</div>
          <div className="col-sm">&nbsp;</div>
        </div>
      </div>
      <div
        className="container-fluid"
        style={{ maxWidth: "95vw", padding: 15 }}
      >
        <div className="row">
          <ul className="nav nav-tabs ">
            <li className="nav-item">
              <Link
                className={[page === "home" ? "nav-link active" : "nav-link"]}
                aria-current="page"
                to="../"
              >
                Kontoplan (drift)
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={[
                  page === "konto-inv" ? "nav-link active" : "nav-link",
                ]}
                aria-current="page"
                to="../konto-inv"
              >
                Kontoplan (investering)
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={[page === "mva" ? "nav-link active" : "nav-link"]}
                to="../mva"
              >
                MVA
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={[page === "faq" ? "nav-link active" : "nav-link"]}
                to="../faq"
              >
                FAQ
              </Link>
            </li>
          </ul>
        </div>

        <div className="row">{children}</div>
      </div>
    </div>
  );
}
