import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function TinyEditor({ data, pushContent }) {
  const editorRef = useRef(null);

  const updateData = (data) => {
    pushContent(data);
  };

  const handleOpen = () => {
    // Juster zIndex for TinyMCE-modalen når den åpnes
    try {
      document.getElementById("editContentModal").style.display = "none";
    } catch (err) {
      return null;
    }
  };

  const handleClose = () => {
    // Gjenopprett den opprinnelige zIndex-verdien når TinyMCE-modalen lukkes
    try {
      document.getElementById("editContentModal").style.display = "block";
    } catch (err) {
      return null;
    }
  };

  return (
    <Editor
      apiKey="bf5c21la29jy0jev9e8rbq08309hk7teey9xixsf6xf8yh8j"
      initialValue={data}
      onEditorChange={updateData}
      //onFocus={handleOpen}
      //onBlur={handleClose}
      ref={editorRef}
      plugins={["link", "lists", "image", "code"]}
      toolbar="undo redo | styles | bold italic | customLinkButton image | numlist bullist | customCodeButton"
      init={{
        image_uploadtab: true,
        images_file_types: "jpeg,jpg",
        images_upload_handler: (blobInfo) => {
          const base64str =
            "data:" + blobInfo.blob().type + ";base64," + blobInfo.base64();
          return Promise.resolve(base64str);
        },

        entities: "raw", // Beholder originale tegn
        encoding: "xml", // Sikrer at tegn ikke blir kodet som HTML-entiteter
        entity_encoding: "raw",

        // Add any other TinyMCE configurations here
        init_instance_callback: (editor) => {
          // Access the underlying TinyMCE editor instance
          const script = document.createElement("script");
          script.src =
            "https://va.yukigassen.no/_template/assets/vendor/bootstrap/js/bootstrap.bundle.min.js";
          script.async = true;
          script.onload = () => {
            // The external script has been loaded
            console.log("External script loaded");
          };
          window.tinymce
            .get(editor.id)
            .getDoc()
            .getElementsByTagName("head")[0]
            .appendChild(script);
        },
        content_css:
          "../../assets/css/style.css?v2, ../../assets/vendor/aos/aos.css, ../../assets/vendor/bootstrap/css/bootstrap.min.css, ../../assets/vendor/bootstrap-icons/bootstrap-icons.css, ../../assets/vendor/boxicons/css/boxicons.min.css, ../../assets/vendor/glightbox/css/glightbox.min.css, ../../assets/vendor/remixicon/remixicon.css, ../../assets/vendor/swiper/swiper-bundle.min.css",

        setup: (editor) => {
          editor.ui.registry.addButton("customCodeButton", {
            icon: "custom-code-icon",
            tooltip: "Insert Code",
            onAction: () => {
              // Legg til koden som skal kjøres når "code" trykkes
              handleOpen();
              editor.execCommand("mceCodeEditor");
            },
          });
          editor.ui.registry.addButton("customLinkButton", {
            icon: "custom-link-icon",
            tooltip: "Insert Link",
            onAction: () => {
              // Legg til koden som skal kjøres når "code" trykkes
              handleOpen();
              editor.execCommand("mceLink");
            },
          });
          editor.ui.registry.addIcon(
            "custom-code-icon",
            '<svg width="24" height="24" focusable="false"><g fill-rule="nonzero"><path d="M9.8 15.7c.3.3.3.8 0 1-.3.4-.9.4-1.2 0l-4.4-4.1a.8.8 0 0 1 0-1.2l4.4-4.2c.3-.3.9-.3 1.2 0 .3.3.3.8 0 1.1L6 12l3.8 3.7ZM14.2 15.7c-.3.3-.3.8 0 1 .4.4.9.4 1.2 0l4.4-4.1c.3-.3.3-.9 0-1.2l-4.4-4.2a.8.8 0 0 0-1.2 0c-.3.3-.3.8 0 1.1L18 12l-3.8 3.7Z"></path></g></svg>'
          );
          editor.ui.registry.addIcon(
            "custom-link-icon",
            '<svg width="24" height="24" focusable="false"><path d="M6.2 12.3a1 1 0 0 1 1.4 1.4l-2 2a2 2 0 1 0 2.6 2.8l4.8-4.8a1 1 0 0 0 0-1.4 1 1 0 1 1 1.4-1.3 2.9 2.9 0 0 1 0 4L9.6 20a3.9 3.9 0 0 1-5.5-5.5l2-2Zm11.6-.6a1 1 0 0 1-1.4-1.4l2-2a2 2 0 1 0-2.6-2.8L11 10.3a1 1 0 0 0 0 1.4A1 1 0 1 1 9.6 13a2.9 2.9 0 0 1 0-4L14.4 4a3.9 3.9 0 0 1 5.5 5.5l-2 2Z" fill-rule="nonzero"></path></svg>'
          );

          // Lytt etter hendelse når kodedialogen er lukket
          editor.on("CloseWindow", (e) => {
            handleClose();
            // Legg til koden du vil kjøre når kodedialogen er lukket
          });

          // Lytt etter hendelse når koden er lagret
          editor.on("SaveContent", (e) => {
            handleClose();
            // Legg til koden du vil kjøre når koden er lagret
          });
        },
      }}
    />
  );
}
