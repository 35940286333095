import React, { useEffect, useState } from "react";
import TinyEditor from "../components/tinyEditor";

export default function MVA() {
  const [data, setData] = useState(null);
  const setContent = async (data) => {
    setData(data);
  };

  return (
    <>
      <LeftBar setContent={setContent} />

      <div
        className="col-sm overflow-auto"
        style={{
          padding: 10,
          borderRadius: 0,
          maxHeight: "90vh",
          backgroundColor: "#8e8a9f",
        }}
      >
        <RightBar data={data} />
      </div>
    </>
  );
}

function RightBar({ data }) {
  const [titleHover, setTitleHover] = useState(false);
  const [changedData, setChangedData] = useState("");

  // Data fra tinyEditor
  const pushContent = async (data) => {
    setChangedData(data);
  };

  // POST data til server
  const updateData = async (title) => {
    try {
      const res = await fetch("https://konto.tkva.no/api/articles.php", {
        method: "POST",
        body: JSON.stringify({
          title: title,
          content: changedData,
          action: "edit",
        }),
      });

      if (!res.ok) {
        alert("Noe gikk galt, prøv igjen");
      } else {
        window.location.reload();
      }

      //const data = await res.json();
      //console.log(data);
    } catch (err) {
      alert("Noe gikk galt, prøv igjen");
      console.log(err);
    }
  };

  if (data)
    return (
      <>
        <div className="card mb-3 bg-secondary">
          <div className="row g-0">
            <div className="col-md-12">
              <div className="card-body">
                <h1
                  className="card-title display-5"
                  style={{
                    letterSpacing: 3,
                    fontWeight: 200,
                  }}
                  onMouseEnter={() => setTitleHover(true)}
                  onMouseLeave={() => setTitleHover(false)}
                >
                  {data.title}
                  {titleHover && (
                    <div style={{ position: "absolute", right: 10, top: 10 }}>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#editContentModal"
                      >
                        Endre innhold
                      </button>
                    </div>
                  )}
                </h1>

                <hr />
                <div className="d-flex">
                  <div className="p-2 flex-grow-1">
                    {data && (
                      <div
                        className="card h-100"
                        style={{
                          backgroundColor: "rgba(255,255,255,0.8)",
                        }}
                      >
                        <div
                          className="card-body"
                          style={{
                            marginLeft: 8,
                            fontSize: 18,
                            letterSpacing: 1,
                            color: "black",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: data.content.replace("\n", "<br />"),
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          {/* Modal */}
          <div
            className="modal fade"
            id="editContentModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div
                className="modal-content"
                style={{ backgroundColor: "grey" }}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {data.title}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <TinyEditor data={data.content} pushContent={pushContent} />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Lukk
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() => updateData(data.title)}
                  >
                    Lagre endringer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    );
}

function LeftBar({ setContent }) {
  const [data, setData] = useState([]);
  const [activeData, setActiveData] = useState(null);
  const [activeCard, setActiveCard] = useState(0);

  const [hoverLeftBar, setHoverLeftBar] = useState(false);

  useEffect(() => {
    const getData = async () => {
      fetch(`https://konto.tkva.no/api/articles.php`)
        .then((response) => response.json())
        .then((data) => {
          setData(data);
          setActiveData(data[0]);
        });
    };
    getData();
  }, []);

  useEffect(() => {
    setContent(activeData);
  }, [activeData]);

  // Data fra tinyEditor
  const [articleTitle, setArticleTitle] = useState("");
  const [articleContent, setArticleContent] = useState("");

  const pushContent = async (data) => {
    setArticleContent(data);
  };

  // POST data til server
  const publishData = async (title) => {
    try {
      const res = await fetch("https://konto.tkva.no/api/articles.php", {
        method: "POST",
        body: JSON.stringify({
          title: articleTitle,
          content: articleContent,
          action: "publish",
        }),
      });

      if (!res.ok) {
        alert("Noe gikk galt, prøv igjen");
      } else {
        window.location.reload();
      }

      //const data = await res.json();
      //console.log(data);
    } catch (err) {
      alert("Noe gikk galt, prøv igjen");
      console.log(err);
    }
  };

  function stripTags(input) {
    // Fjern HTML-tags
    var withoutTags = input.replace(/<\/?[^>]+(>|$)/g, "");

    // Dekode HTML-entiteter
    var decodedString = withoutTags.replace(
      /&([^\s;]+);/g,
      function (match, entity) {
        var decodeEntities = {
          amp: "&",
          lt: "<",
          gt: ">",
          quot: '"',
          apos: "'",
          nbsp: " ",
          ouml: "ö", // Legg til flere entiteter etter behov
          oslash: "ø",
          aring: "å",
          // Legg til flere entiteter etter behov
        };
        return decodeEntities[entity] || match;
      }
    );

    return decodedString;
  }

  return (
    <div
      className="col-sm-4"
      style={{
        backgroundImage: `url(https://images.unsplash.com/photo-1519750783826-e2420f4d687f?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGJyaWdodHxlbnwwfHwwfHx8MA%3D%3D)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: 10,
        borderRadius: 0,
        minHeight: "90vh",
      }}
    >
      <div
        className="col"
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          paddingLeft: 5,
          minHeight: "100%",
          borderRadius: 3,
          padding: 5,
        }}
      >
        <div
          style={{ height: "80vh", position: "relative", paddingTop: 0 }}
          className="overflow-auto"
          onMouseEnter={() => setHoverLeftBar(true)}
          onMouseLeave={() => setHoverLeftBar(false)}
        >
          {hoverLeftBar && (
            <div style={{ position: "absolute", right: 0, top: 5, zIndex: 2 }}>
              <button
                type="button"
                className="btn btn-success btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#addContentModal"
              >
                +
              </button>
            </div>
          )}

          {data ? (
            <>
              {data.map((res, key) => {
                return (
                  <div
                    className={
                      activeCard === key ? "card bg-secondary" : "card bg-dark"
                    }
                    key={key}
                    style={{ width: "90%", margin: 15, cursor: "pointer" }}
                    onClick={() => {
                      setActiveCard(key);
                      setActiveData(data[key]);
                    }}
                  >
                    <div className="card-header">
                      <p style={{ fontSize: 20, padding: 0, margin: 0 }}>
                        {res.title}
                      </p>
                    </div>
                    <div
                      className="card-body"
                      style={{ backgroundColor: "lightgrey", margin: 2 }}
                    >
                      <blockquote className="blockquote mb-0">
                        <p
                          style={{
                            color: "black",
                            fontWeight: 400,
                            fontSize: 18,
                          }}
                          className="text-truncate"
                        >
                          {stripTags(res.content)}
                        </p>
                      </blockquote>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <p>Ingen resultater</p>
          )}
        </div>
      </div>

      <>
        {/* Modal */}
        <div
          className="modal fade"
          id="addContentModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content" style={{ backgroundColor: "grey" }}>
              <div className="modal-header">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Tittel"
                    onChange={(e) => setArticleTitle(e.target.value)}
                    required={true}
                  />
                </div>
              </div>
              <div className="modal-body">
                <TinyEditor
                  data={"Fyll inn beskrivelse"}
                  pushContent={pushContent}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Lukk
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  data-bs-dismiss="modal"
                  onClick={() => publishData()}
                >
                  Publiser
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
