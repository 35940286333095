import React, { useState, useRef } from "react";
import Search from "../components/search";
import Veiledning from "../components/veiledning";

export default function Home({ type }) {
  // Dette forvaltes i Search search.js og sendes til konto.js
  const [kontoResults, setKontoResults] = useState([]);
  const [chosenKonto, setChosenKonto] = useState(null); // Dette er data som Veiledning.js viser

  const [keyword, setKeyword] = useState("");

  // Ref til Child Komponent
  const searchRef = useRef();

  const updateKonto = async (data) => {
    setKontoResults(data);
    setChosenKonto(data[0]); // Det er denne som må løses når man oppdaterer innhold

    /*
    setChosenKonto((prev) => {
      try {
        // Finn indeksen til elementet i 'data' som har samme verdi som 'prev.konto'
        const index = data.findIndex((item) => item.konto === prev.konto);

        // Returner det første elementet i 'data' hvis ingen match ble funnet, ellers returner elementet ved indeksen
        return index !== -1 ? data[index] : data[0];
      } catch (err) {
        return data[0];
      }
    });
    */
  };

  const updateActiveCard = async (data) => {
    setChosenKonto(kontoResults[data]);
  };

  const refreshData = (konto) => {
    searchRef.current.getData(konto);
  };

  const updateKeyword = (keyword) => {
    setKeyword(keyword);
  };

  return (
    <>
      {/* Søkefunskjon */}
      <Search
        ref={searchRef}
        updateData={updateKonto}
        updateCard={updateActiveCard}
        updateKeyword={updateKeyword}
        type={type}
      />

      <div
        className="col-sm overflow-auto"
        style={{
          padding: 10,
          borderRadius: 0,
          maxHeight: "90vh",
          backgroundColor: "#8e8a9f",
        }}
      >
        <Veiledning
          data={chosenKonto}
          keyword={keyword}
          refreshData={refreshData}
        />
      </div>
    </>
  );
}
