import React, { useEffect, useState } from "react";
import TinyEditor from "./tinyEditor";

export default function Veiledning({ data, keyword, refreshData }) {
  const [mvaData, setMvaData] = useState([]);
  const [hoverVeil, setHoverVeil] = useState(false);

  useEffect(() => {
    if (data) {
      // MVA
      fetch(`https://konto.tkva.no/api/mva?konto=${data.konto}`)
        .then((response) => response.json())
        .then((data) => setMvaData(data));
    }
  }, [data]);

  // Data fra tinyEditor
  const [newBeskrivelse, setNewBeskrivelse] = useState("");
  const [newKommentar, setNewKommentar] = useState("");

  const pushBeskrivelse = async (data) => {
    setNewBeskrivelse(data);
  };
  const pushKommentar = async (data) => {
    setNewKommentar(data);
  };

  // POST data til server
  const updateData = async (konto) => {
    try {
      const res = await fetch("https://konto.tkva.no/api/konto.php", {
        method: "POST",
        body: JSON.stringify({
          konto: konto,
          beskrivelse: newBeskrivelse,
          kommentar: newKommentar,
          type: "drift",
        }),
      });

      if (!res.ok) {
        alert("Noe gikk galt, prøv igjen");
      } else {
        //window.location.reload();
        //const data = await res.json();
        //console.log(data);
        refreshData(konto);
      }
    } catch (err) {
      alert("Noe gikk galt, prøv igjen");
      console.log(err);
    }
  };

  const highlightKeyword = (text, keyword) => {
    if (!text || !keyword) {
      return text;
    }

    // Bruk en regulær uttrykk for å finne alle forekomster av nøkkelordet i teksten
    const regex = new RegExp(keyword, "gi");
    return text.replace(regex, (match) => `<b><u>${match}</u></b>`);
  };

  if (data) {
    return (
      <>
        <div
          className="card mb-3"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}
          onMouseEnter={() => setHoverVeil(true)}
          onMouseLeave={() => setHoverVeil(false)}
        >
          <div className="row g-0">
            <div className="row">
              <div
                className="col-md-1 text-center my-4"
                style={{
                  backgroundColor: "lightblue",
                  marginLeft: 10,
                }}
              >
                <p
                  style={{
                    color: "#000",
                    fontWeight: 400,
                    fontSize: 20,
                    letterSpacing: 4,
                    marginTop: 5,
                  }}
                >
                  ART
                </p>
              </div>
              <div className="col-md-10 my-4">
                <p
                  style={{
                    letterSpacing: 2,
                    fontWeight: 600,
                    marginTop: 10,
                    color: "#000",
                  }}
                >
                  {data.kontoart}
                </p>
              </div>
              <div className="col-10">
                <p
                  className="lead"
                  style={{ fontStyle: "italic", color: "#000" }}
                >
                  Beskrivelse av art kommer
                </p>
              </div>
            </div>
          </div>
          {hoverVeil && (
            <div style={{ position: "absolute", right: 15, top: 15 }}>
              <i
                className="bi bi-pencil"
                data-bs-toggle="modal"
                data-bs-target="#editContentModal"
                style={{ color: "#434b16", cursor: "pointer" }}
              />
            </div>
          )}
        </div>

        <div className="card mb-3 bg-secondary">
          <div className="row g-0">
            <div className="col-md-12">
              <div className="card-body">
                <h1
                  className="card-title display-5"
                  style={{
                    letterSpacing: 3,
                    fontWeight: 200,
                  }}
                >
                  {data.konto} - {data.kontonavn}
                </h1>
                <hr />
                <div className="d-flex">
                  <div className="p-2 flex-grow-1">
                    {data.beskrivelse && (
                      <div
                        className="card h-100"
                        style={{
                          backgroundColor: "rgba(255,255,255,0.8)",
                        }}
                      >
                        <div
                          className="card-body"
                          style={{
                            marginLeft: 8,
                            fontSize: 18,
                            letterSpacing: 1,
                            color: "black",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: highlightKeyword(
                              data.beskrivelse,
                              keyword
                            ).replace("\n", "<br />"),
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="p-2 ml-auto">
                    <h1
                      className="display-6"
                      style={{
                        color: "lightgrey",
                        letterSpacing: 4,
                        fontSize: 26,
                        fontWeight: 200,
                        writingMode: "vertical-rl",
                      }}
                    >
                      Beskrivelse
                    </h1>
                  </div>
                </div>

                {data.kommentar && (
                  <>
                    <hr />
                    <div className="d-flex">
                      <div className="p-2 flex-grow-1">
                        {data.kommentar && (
                          <div
                            className="card h-100"
                            style={{
                              backgroundColor: "rgba(255,255,255,0.8)",
                            }}
                          >
                            <div
                              className="card-body"
                              style={{
                                marginLeft: 8,
                                fontSize: 18,
                                letterSpacing: 1,
                                color: "black",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: highlightKeyword(
                                  data.kommentar,
                                  keyword
                                ).replace("\n", "<br />"),
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="p-2 ml-auto">
                        <h1
                          className="display-6"
                          style={{
                            color: "lightgrey",
                            letterSpacing: 4,
                            fontSize: 26,
                            fontWeight: 200,
                            writingMode: "vertical-rl",
                          }}
                        >
                          Kommentar
                        </h1>
                      </div>
                    </div>
                  </>
                )}
                <hr />
                <h6 style={{ color: "lightgrey", letterSpacing: 4 }}>MVA</h6>
                <ul className="list-group">
                  {mvaData.map((mva, k) => {
                    return (
                      <li
                        key={k}
                        className="list-group-item d-flex justify-content-between align-items-start"
                      >
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">Avgiftskode: {mva.type}</div>
                          {mva.beskrivelse}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <>
          {/* Modal */}
          <div
            className="modal fade"
            id="editContentModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div
                className="modal-content"
                style={{ backgroundColor: "grey" }}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {data.konto} - {data.kontonavn}
                  </h5>
                </div>
                <div className="modal-body">
                  <h4>Beskrivelse</h4>
                  <TinyEditor
                    data={data.beskrivelse ? data.beskrivelse : ""}
                    pushContent={pushBeskrivelse}
                  />
                </div>
                <div className="modal-body">
                  <h4>Kommentar</h4>
                  <TinyEditor
                    data={data.kommentar ? data.kommentar : ""}
                    pushContent={pushKommentar}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Lukk
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    data-bs-dismiss="modal"
                    onClick={() => updateData(data.konto)}
                  >
                    Publiser
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}
